<template>
  <v-card flat class="mt-5">
    <v-form ref="form1" v-model="valid1">
      <div class="px-3">
        <v-card-title>Change Password</v-card-title>
        <v-card-text class="pt-5">
          <v-row>
            <v-col cols="12" sm="8" md="6">
              <!-- current password -->
              <v-text-field
                v-model="currentPassword"
                :rules="currentPasswordRules"
                :type="isCurrentPasswordVisible ? 'text' : 'password'"
                :append-icon="isCurrentPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                label="Current Password"
                outlined
                dense
                @click:append="isCurrentPasswordVisible = !isCurrentPasswordVisible"
              ></v-text-field>

              <!-- new password -->
              <v-text-field
                v-model="newPassword"
                :rules="newPasswordRules"
                :type="isNewPasswordVisible ? 'text' : 'password'"
                :append-icon="isNewPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                label="New Password"
                outlined
                dense
                hint="Make sure it's at least 8 characters."
                persistent-hint
                @click:append="isNewPasswordVisible = !isNewPasswordVisible"
              ></v-text-field>

              <!-- confirm password -->
              <v-text-field
                v-model="cPassword"
                :rules="cPasswordRules"
                :type="isCPasswordVisible ? 'text' : 'password'"
                :append-icon="isCPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                label="Confirm New Password"
                outlined
                dense
                class="mt-3"
                @click:append="isCPasswordVisible = !isCPasswordVisible"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="4" md="6" class="d-none d-sm-flex justify-center position-relative">
              <v-img
                contain
                max-width="170"
                src="@/assets/images/3d-characters/pose-m-1.png"
                class="security-character"
              ></v-img>
            </v-col>
          </v-row>
        </v-card-text>
      </div>

      <!-- divider -->
      <v-divider></v-divider>

      <div class="pa-3">
        <!-- action buttons -->
        <v-card-text>
          <v-btn color="primary" class="me-3 mt-3" @click="requestVerificationCode">Change Password</v-btn>
          <v-btn color="secondary" outlined class="mt-3" @click="resetAllForm">Cancel</v-btn>
        </v-card-text>
      </div>
    </v-form>

    <v-dialog v-model="dialog" persistent max-width="350">
      <v-form v-model="valid2" ref="form2">
        <v-card>
          <v-card-title class="text-h5">Enter Verification Code</v-card-title>
          <v-card-text
            >A Verification Code has been sent to {{ $store.state.email }}, please enter it to verify your
            account.</v-card-text
          >
          <v-card-text>
            <v-text-field
              v-model="verificationCode"
              :rules="verificationCodeRules"
              counter="6"
              required
              label="Verification Code"
              outlined
            ></v-text-field>
            <a @click="resedVerificationCode">Re-send verification code.</a>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" outlined @click="changePassword">Confirm</v-btn>
            <v-btn color="green darken-1" text @click="dialog = false">Cancel</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
// import { mdiKeyOutline, mdiLockOpenOutline, mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'
// import { ref } from '@vue/composition-api'

export default {
  data: () => ({
    isCurrentPasswordVisible: false,
    isNewPasswordVisible: false,
    isCPasswordVisible: false,
    currentPassword: '',
    currentPasswordRules: [v => !!v || 'Current Password is required', v => v.length >= 6 || 'Min 6 characters'],
    newPassword: '',
    newPasswordRules: [v => !!v || 'New Password is required', v => v.length >= 6 || 'Min 6 characters'],
    cPassword: '',
    cPasswordRules: [v => !!v || 'Confirm Password is required', v => v.length >= 6 || 'Min 6 characters'],
    icons: {
      mdiKeyOutline: 'mdi-key-outline',
      mdiLockOpenOutline: 'mdi-lock-open-outline',
      mdiEyeOffOutline: 'mdi-eye-off-outline',
      mdiEyeOutline: 'mdi-eye-outline',
    },
    dialog: false,
    verificationCode: '',
    verificationCodeRules: [
      v => !!v || 'Verification Code is required',
      v => v.length == 6 || 'Code must 6 characters',
    ],
    valid1: false,
    valid2: false,
  }),
  methods: {
    resetAllForm() {
      this.currentPassword = ''
      this.newPassword = ''
      this.cPassword = ''

      this.verificationCode = ''
    },
    async requestVerificationCode() {
      this.validateForm1()

      this.verificationCode = ''

      if (!this.valid1) {
        return false
      }

      var formData = new FormData()
      formData.append('current_password', this.currentPassword)
      formData.append('new_password', this.newPassword)
      formData.append('conf_password', this.cPassword)

      this.$store.state.showLoadingDialog = true
      await this.$axios
        .post('account/requestverificationcode', formData)
        .then(res => {
          this.$store.state.showLoadingDialog = false
          if (typeof res !== 'undefined') {
            var resData = res.data

            if (resData.status == 'success') {
              this.$awn.success(resData.message)
              this.dialog = true
            } else {
              this.$awn.warning(resData.message)
            }
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch(error => {
          // console.log(error)
          this.$awn.alert('Internal Server Error!')
          this.$store.state.showLoadingDialog = false
        })
        .finally(() => (this.$store.state.showLoadingDialog = false))
    },
    resedVerificationCode() {
      this.verificationCode = ''

      this.dialog = false

      this.requestVerificationCode()
    },
    validateForm1() {
      this.$refs.form1.validate()
    },
    validateForm2() {
      this.$refs.form2.validate()
    },
    async changePassword() {
      this.validateForm2()

      if (!this.valid2) {
        return false
      }

      this.$store.state.showLoadingDialog = true

      var formData = new FormData()
      formData.append('current_password', this.currentPassword)
      formData.append('new_password', this.newPassword)
      formData.append('conf_password', this.cPassword)
      formData.append('verification_code', this.verificationCode)

      await this.$axios
        .post('account/changepassword', formData)
        .then(res => {
          this.$store.state.showLoadingDialog = false
          if (typeof res !== 'undefined') {
            var resData = res.data

            if (resData.status == 'success') {
              alert(resData.message)

              this.signOut()
            } else {
              this.$awn.warning(resData.message)
            }
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch(error => {
          // console.log(error)
          this.$store.state.showLoadingDialog = false
          this.$awn.alert('Internal Server Error')
        })
        .finally(() => (this.$store.state.showLoadingDialog = false))
    },
  },
  mounted() {},
}
</script>

<style lang="scss" scoped>
.two-factor-auth {
  max-width: 25rem;
}
.security-character {
  position: absolute;
  bottom: -0.5rem;
}
</style>
