<template>
  <v-card flat class="pa-3 mt-2" :loading="loading">
    <v-card-text class="d-flex">
      <v-avatar rounded size="120" class="me-6">
        <v-img :src="accountData.user_img">
          <!-- <v-btn link color="primary" fab x-small elevation="3" @click="$refs.refInputEl.click()" title="Change Photo">
            <v-icon small>mdi-pencil</v-icon>
          </v-btn> -->
        </v-img>
      </v-avatar>

      <!-- upload photo -->
      <div>
        <v-btn color="primary" class="me-3 mt-5" @click="$refs.refInputEl.click()">
          <v-icon class="d-sm-none">{{ icons.mdiCloudUploadOutline }}</v-icon>
          <span class="d-none d-sm-block">Upload new photo</span>
        </v-btn>
        <input
          type="file"
          ref="refInputEl"
          :v-model="photoFile"
          accept=".jpeg, .png, .jpg, GIF"
          truncate-length="15"
          @change="confirmChangePhoto"
          v-show="false"
         />
        
        <p class="text-sm mt-5">Allowed JPG, GIF or PNG.</p>
      </div>
    </v-card-text>

    <v-card-text>
      <v-row>
        <v-col md="6" cols="12">
          <v-text-field v-model="accountData.username" label="Username" dense outlined readonly></v-text-field>
        </v-col>

        <v-col md="6" cols="12">
          <v-text-field v-model="accountData.first_name" label="Name" dense outlined readonly></v-text-field>
        </v-col>

        <v-col cols="12" md="6">
          <v-text-field v-model="accountData.email" label="E-mail" dense outlined readonly></v-text-field>
        </v-col>

        <v-col cols="12" md="6">
          <v-text-field v-model="accountData.phone" dense label="Phone" outlined readonly></v-text-field>
        </v-col>

        <v-col cols="12" md="6">
          <v-text-field v-model="accountData.company" dense outlined label="Company" readonly></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiCloudUploadOutline } from '@mdi/js'
// import { ref } from '@vue/composition-api'

export default {
  data: () => ({
    icons: {
      mdiCloudUploadOutline,
    },
    photoFile: null,
    loading: null,
    accountData: {},
  }),

  methods:{
    async getAccountInformation() {
      this.loading = true
      await this.$axios.get('profile', { cacheConfig: true, }).then(res => {
        var resData = res.data
        if (typeof resData !== 'undefined') {
          this.accountData = resData.data
        }
        
        this.loading = false
      })
    },
    confirmChangePhoto(){
      let onOk = () => {
        this.changePhotoProfile()
      };
      this.$awn.confirm("Are you sure to change photo?", 
        onOk,
        null,
        {
          labels: {
            confirm: 'Confirm'
          }
        });
    },
    changePhotoProfile(){
      try {
        this.loading = true
        this.photoFile = this.$refs.refInputEl.files[0];
        let formData = new FormData();
        formData.append("user_img", this.photoFile);
        this.$axios.post('user/uploadphotoprofile', formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          // eslint-disable-next-line no-unused-vars
          }).then((response) => {
            this.loading = false
            this.showAlert(response.data.status, response.data.message)
            this.getAccountInformation();
          // eslint-disable-next-line no-unused-vars
          }).catch((error) => {
            this.loading = false
            this.showAlert("error", "Opps... something is wrong, we will fix it soon. ")
            // console.log(error);
          });
      } catch (error) {
            this.loading = false
            this.showAlert("error", "Opps... something is wrong, we will fix it soon. ")
      }
    }
  },

  mounted(){
    this.getAccountInformation();
  }
}
</script>
