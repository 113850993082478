<template>
  <div>
    <page-title title="Account" :breadcrumbs-items="breadcrumbsItems"></page-title>
    <v-card id="account-setting-card">
      <!-- tabs -->
      <v-tabs v-model="tab" show-arrows>
        <v-tab v-for="tab in tabs" :key="tab.icon">
          <v-icon size="20" class="me-3">{{ tab.icon }}</v-icon>
          <span>{{ tab.title }}</span>
        </v-tab>
      </v-tabs>

      <!-- tabs item -->
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <account-settings-account></account-settings-account>
        </v-tab-item>

        <v-tab-item>
          <account-settings-security></account-settings-security>
        </v-tab-item>

        <v-tab-item>
          <account-settings-info></account-settings-info>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import { mdiAccountOutline, mdiLockOpenOutline, mdiInformationOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'

// demos
import AccountSettingsAccount from './AccountSettingsAccount.vue'
import AccountSettingsSecurity from './AccountSettingsSecurity.vue'
import AccountSettingsInfo from './AccountSettingsInfo.vue'

export default {
  name: 'Account',
  components: {
    AccountSettingsAccount,
    AccountSettingsSecurity,
    AccountSettingsInfo,
  },
  data: () => ({
    tab: ref(''),
    tabs: [
      { title: 'Account', icon: mdiAccountOutline },
      { title: 'Security', icon: mdiLockOpenOutline },
      // { title: 'Info', icon: mdiInformationOutline },
    ],
    accountData: {},
    icons: {
      mdiAccountOutline,
      mdiLockOpenOutline,
      mdiInformationOutline,
    },
    breadcrumbsItems: [
      {
        text: 'Dashboard',
        disabled: false,
        href: '#',
      },
      {
        text: 'Account',
        disabled: true,
        href: '#',
      },
    ],
  }),
  methods: {
    async getAccountInformation() {
      await this.$axios.get('profile', { cacheConfig: true }).then(res => {
        var resData = res.data
        if (typeof resData !== 'undefined') {
          this.accountData = resData.data
        }
      })
    },
  },
  beforeMount() {
    this.getAccountInformation()
  },
  mounted() {
    document.title = 'Account'
    this.$store.state.pageTitle = document.title
  },
}
</script>
