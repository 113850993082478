<template>
  <v-card flat class="pa-3 mt-2" :loading="loading">
    <v-form class="multi-col-validation">
      <v-card-title>Account Info</v-card-title>
      <v-card-text class="pt-5">
        <v-row>
          <v-col cols="12">
            <v-textarea v-model="accountData.bio" outlined rows="3" label="Bio"></v-textarea>
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field v-model="accountData.birthday" outlined dense label="Birthday"></v-text-field>
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field v-model="accountData.phone" outlined dense label="Phone"></v-text-field>
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field v-model="accountData.website" outlined dense label="Website"></v-text-field>
          </v-col>

          <v-col cols="12" md="6">
            <v-select
              v-model="accountData.country"
              outlined
              dense
              label="Country"
              :items="['USA', 'UK', 'AUSTRALIA', 'BRAZIL']"
            ></v-select>
          </v-col>

          <v-col cols="12" md="6">
            <v-select
              v-model="accountData.languages"
              outlined
              dense
              multiple
              chips
              small-chips
              label="Languages"
              :items="['English', 'Spanish', 'French', 'German']"
            ></v-select>
          </v-col>

          <v-col cols="12" md="6">
            <p class="text--primary mt-n3 mb-2">Gender</p>
            <v-radio-group v-model="accountData.gender" row class="mt-0" hide-details>
              <v-radio value="male" label="Male"> </v-radio>
              <v-radio value="female" label="Female"> </v-radio>
              <v-radio value="other" label="Other"> </v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text>
        <v-btn color="primary" class="me-3 mt-3"> Save changes </v-btn>
        <v-btn outlined class="mt-3" color="secondary" type="reset" @click.prevent="resetForm"> Cancel </v-btn>
      </v-card-text>
    </v-form>
  </v-card>
</template>

<script>

export default {

  data: () => ({
    loading: null,
    accountData: {},
  }),
  
  methods: {
    
    async getAccountInformation() {
      this.loading = true
      await this.$axios.get('profile', { cacheConfig: true, }).then(res => {
        var resData = res.data
        if (typeof resData !== 'undefined') {
          this.accountData = resData.data
        }
        
        this.loading = false
      })
    },

  },

  mounted() {
    this.getAccountInformation();
  }
}
</script>
